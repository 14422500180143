import {
  addBookingsPagesAsPanel,
  createBookCheckoutState,
  createBookingFormState,
  createCalendarPageState,
  createServicePageState,
} from './pages-panel-actions';

export async function handlePagesPanelMigration(sdk, appToken, allSitePages) {
  await createBookCheckoutState(sdk, appToken, allSitePages);
  await createServicePageState(sdk, appToken, allSitePages);
  await createCalendarPageState(sdk, appToken, allSitePages);
  await createBookingFormState(sdk, appToken, allSitePages);
  await addBookingsPagesAsPanel(sdk, appToken);
}

export function shouldProposeMigration(editorSdk, isAdi, appToken, instance) {
  const isFromTemplate = !JSON.parse(atob(instance.split('.')[1])).metaSiteId;
  return !isAdi && !isFromTemplate;
}

export const updateProperties = async (state: boolean) => {
  // TODO: call to updateProperties ( isCartEnabled = state )
  return;
}

export const getProperties = async () => {
  // TODO: call to getProperties ( isCartEnabled )
  return false;
}
