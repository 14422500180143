import { BOOKINGS_DEF_ID, PageId } from '../constants';
import {
  ComponentDataType,
  ComponentStructure,
  ComponentType,
} from '../migration/domain';
import {
  EcomComponent,
} from '@wix/ecom-platform-sdk';
import { EditorSDK } from '@wix/platform-editor-sdk';
import { EditorScriptApi } from '../api/api';

export const isBookingsCheckoutInstalled = async (sdk) => {
  const allSitePages = await sdk.pages.data.getAll();
  return !!allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_CHECKOUT,
  );
};

export const isEcomCheckoutInstalled = async (sdk) => {
  const allSitePages = await sdk.pages.data.getAll();
  return !!allSitePages.find(
    (page) => page.tpaPageId === EcomComponent.CHECKOUT,
  );
};

export const isEcomThankYouPageInstalled = async (sdk) => {
  const allSitePages = await sdk.pages.data.getAll();
  return !!allSitePages.find(
    (page) => (page.tpaPageId === EcomComponent.THANK_YOU_PAGE || page.tpaPageId === 'thank_you_page'),
  );
};

export const isEcomCartInstalled = async (sdk) => {
  const allSitePages = await sdk.pages.data.getAll();
  return !!allSitePages.find(
    (page) => (page.tpaPageId === EcomComponent.CART),
  );
};


export const isBookingsListInstalled = async (sdk) => {
  const allSitePages = await sdk.pages.data.getAll();
  return !!allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_LIST,
  );
};

export const isServicePageInstalled = async (sdk) => {
  const allSitePages = await sdk.pages.data.getAll();
  return !!allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_SERVICE_PAGE,
  );
};

export const isBookingCalendarInstalled = async (sdk) => {
  try {
    const allSitePages = await sdk.pages.data.getAll();
    return !!allSitePages.find(
      (page) => page.tpaPageId === PageId.BOOKINGS_CALENDAR_PAGE,
    );
  } catch (e) {
    const errorMessage = `isBookingCalendarInstalled Failed - ${(e?.message ? e.message : JSON.stringify(e))}`;
    throw new Error(errorMessage);
  }
};

export const isBookingFormInstalled = async (sdk) => {
  const allSitePages = await sdk.pages.data.getAll();
  return !!allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_FORM_PAGE,
  );
};

export const isNoBookingsPagesInstalled = async (sdk) => {
  const allSitePages = await sdk.pages.data.getAll();
  const bookingsPages = allSitePages.filter(
    (page) =>
      page.tpaPageId === PageId.SCHEDULER ||
      page.tpaPageId === PageId.BOOKINGS_LIST ||
      page.tpaPageId === PageId.BOOKINGS_CHECKOUT,
  );
  return !bookingsPages.length;
};

export const getAllBookingsAppBuilderWidgets = async (sdk, appToken) => {
  const allComponentsPromises = (
    await sdk.document.components.getAllComponents(appToken)
  ).map((componentRef) =>
    sdk.components.data
      .get('token', { componentRef })
      .then((componentData) => ({
        componentData,
        componentRef,
      })),
  );
  const bookingsTimetableWidgets = (
    await Promise.all(allComponentsPromises)
  ).filter(
    ({ componentData }) =>
      componentData &&
      componentData.appDefinitionId === appToken &&
      componentData.type === 'WidgetRef',
  );
  return bookingsTimetableWidgets;
};

export const getStateBoxByBookingsAppBuilderWidget = async (
  sdk,
  appToken,
  bookingsAppBuilderWidgetComponentRef,
) => {
  const appBuilderWidgetAllComponents = (
    await sdk.document.components.getChildren(appToken, {
      componentRef: bookingsAppBuilderWidgetComponentRef,
      recursive: true,
    })
  ).map((componentRef) =>
    sdk.components
      .getType(appToken, { componentRef })
      .then((componentType) => ({
        componentType,
        componentRef,
      })),
  );

  const stateBoxComponent = (
    await Promise.all(appBuilderWidgetAllComponents)
  ).filter(
    ({ componentType }) =>
      componentType === 'wysiwyg.viewer.components.StateBox',
  );
  return (stateBoxComponent.pop() as any).componentRef;
};

export const getBookingsAppBuilderWidgetByChildComponentRef = async (
  sdk,
  appToken,
  childComponentRef,
) => {
  const allComponentsPromises = (
    await sdk.document.components.getAncestors(appToken, {
      componentRef: childComponentRef,
    })
  ).map((componentRef) =>
    sdk.components.data
      .get('token', { componentRef })
      .then((componentData) => ({
        componentData,
        componentRef,
      })),
  );
  const bookingsTimetableWidgets = (
    await Promise.all(allComponentsPromises)
  ).filter(
    ({ componentData }) =>
      componentData &&
      componentData.appDefinitionId === appToken &&
      componentData.type === 'WidgetRef',
  );
  return bookingsTimetableWidgets.pop();
};

export const onRemoveApp = async (sdk, appToken) => {
  try {
    const componentsToDelete = await getAllBookingsAppBuilderWidgets(
      sdk,
      appToken,
    );
    await Promise.all(
      componentsToDelete.map(({ componentRef }) =>
        sdk.document.components.remove('token', { componentRef }),
      ),
    );
    return 'OK';
  } catch (e) {
    console.error('Failed to remove Ref Widgets', e);
    return 'Error';
  }
};

export const isBookingInstalled = async (sdk) => sdk.document.application.isApplicationInstalled(BOOKINGS_DEF_ID, { appDefinitionId: BOOKINGS_DEF_ID });

export const getBookingsDefId = (sdk) => sdk.info.getAppDefinitionId();

export const getBookingsData = async (sdk, appToken) =>
  sdk.document.tpa.app.getDataByAppDefId(appToken, await getBookingsDefId(sdk));

export const getEditorSdkSrc = (sdk) => sdk.info.getSdkVersion().scriptSrc;

export const updateComponentStyle = (sdk, appToken, compId, style) =>
  sdk.document.components.style.update(appToken, {
    componentRef: { type: 'DESKTOP', id: compId },
    style,
  });

export const updateComponentData = (sdk, appToken, componentRef, data) =>
  sdk.document.components.data.update(appToken, { componentRef, data });

export const updatePageData = (sdk, appToken, pageRef, data) =>
  sdk.pages.data.update(appToken, { pageRef, data });

export const getComponentData = (sdk, appToken, componentRef) =>
  sdk.document.components.data.get(appToken, { componentRef });

export const getPageData = (sdk, appToken, pageRef) =>
  sdk.document.pages.getPageData(appToken, { pageRef });

export const getComponentRefById = (sdk, appToken, compId) =>
  sdk.document.components.getById(appToken, { id: compId });

export const getAllBookingsPages = (sdk, appToken) =>
  sdk.document.pages.getApplicationPages(appToken);

export const navigateToPage = (sdk, appToken, pageId) =>
  sdk.document.pages.navigateTo(appToken, {
    pageRef: { type: 'DESKTOP', id: pageId },
  });

export const getAllSiteComponents = (sdk, appToken) =>
  sdk.document.components.getAllComponents(appToken);

export const getFullComponentStructure = async (sdk, appToken, componentRef) => {
  try {
    const result = await sdk.document.components.serialize(appToken, {
      componentRef,
      maintainIdentifiers: true,
    });

    return {
      id: componentRef.id,
      ...result
    }
  } catch (e) {
    return {};
    /*
    const errorMessage = `errorCode: SERIALIZE_COMPONENT_FAILED - ${(e?.message ? e.message : JSON.stringify(e))}`;
    throw new Error(errorMessage);
     */
  }
}

export const getPageRefByComponentRef = (sdk, appToken, componentRef) =>
  sdk.components.getPage(appToken, { componentRef });

export const removePage = (sdk, appToken, pageId) =>
  sdk.document.pages.remove(appToken, { pageRef: { id: pageId } });

export const updateControllerConfiguration = (sdk, appToken, compId, config) =>
  sdk.document.controllers.saveConfiguration(appToken, {
    controllerRef: { type: 'DESKTOP', id: compId },
    config,
  });

export const getTPAMainSectionStructure = async (sdk, appToken, tpaId) => {
  const allComponents = await getAllSiteComponents(sdk, appToken);
  const siteStructure: any[] = await (async () => {
    return Promise.all(
      allComponents.map((componentRef) =>
        getFullComponentStructure(sdk, appToken, componentRef),
      ),
    );
  })();
  const compStructures: ComponentStructure[] = siteStructure.filter(
    (comp) => comp?.type === ComponentType.COMPONENT || comp?.type === ComponentType.CONTAINER,
  );

  return compStructures.find(
    (comp) =>
      comp?.data &&
      comp?.data?.appDefinitionId === tpaId &&
      comp?.data?.type === ComponentDataType.MAIN_SECTION,
  );
};

export const getTPAMainSectionPageRef = async (sdk, appToken, tpaId) => {
  const tpaMainSectionStructure = await getTPAMainSectionStructure(
    sdk,
    appToken,
    tpaId,
  );
  const tpaMainSectionRef = await getComponentRefById(
    sdk,
    appToken,
    tpaMainSectionStructure.id,
  );
  return getPageRefByComponentRef(sdk, appToken, tpaMainSectionRef);
};

export const ecomRolloutButNotSite = async (sdk: EditorSDK, editorScriptApi: EditorScriptApi) => {
  const isBookingRollout = await editorScriptApi.getIsBookingsOnEcom();
  const bookingInstalled = await isBookingInstalled(sdk);
  const ecomCheckoutInstalled = await isEcomCheckoutInstalled(sdk);
  const ecomThankYouPageInstalled = await isEcomThankYouPageInstalled(sdk);

  return (bookingInstalled && isBookingRollout && (!ecomCheckoutInstalled || !ecomThankYouPageInstalled));
};
